import CastBox from 'images/castbox.svg';
import CustomLinkDestination from 'images/custom-link-destination.svg';
import GooglePodcasts from 'images/google_podcasts_logo.png';
import iHeartRadio from 'images/i_heart_radio.png';
import FacebookIcon from 'images/icons/facebook.svg';
import GoogleIcon from 'images/icons/google_tag_manager.svg';
import TikTokIcon from 'images/icons/tiktok.svg';
import AmazonMusic from 'images/logo-amazon-music.png';
import ItunesConnect from 'images/logo-apple-podcasts.png';
import Breaker from 'images/logo-breaker.png';
import PocketCasts from 'images/logo-pocket-casts.png';
import PodcastAddict from 'images/logo-podcast-addict.png';
import RadioDotCom from 'images/logo-radio_com.png';
import Uforia from 'images/logo-uforia.png';
import OverCast from 'images/overcast.svg';
import Pandora from 'images/pandora.png';
import RadioPublic from 'images/radio_public.svg';
import Rss from 'images/rss.png';
import Spotify from 'images/spotify.png';
import YouTube from 'images/youtube.png';
import moment from 'moment';

export const remarketingPixelPlatforms = {
  facebook: { label: 'Facebook', icon: FacebookIcon, value: 'facebook' },
  google_tag_manager: {
    label: 'Google Tag Manager',
    icon: GoogleIcon,
    value: 'google_tag_manager',
  },
  tiktok: { label: 'TikTok', icon: TikTokIcon, value: 'tiktok' },
  // reddit:   { label: 'Reddit', icon: RedditIcon, value: 'reddit' },
};

export const remarketingPixelPlatformKeys = [
  'facebook',
  'google_tag_manager',
  'tiktok',
  // 'reddit'
];

export const providerDisplayNames = {
  acast: 'Acast',
  adswizz: 'AdsWizz',
  art19: 'Art19',
  art19_marketplace: 'Art19 Marketplace',
  audacy: 'Audacy',
  knit: 'Knit',
  megaphone: 'Megaphone',
  mtm: 'Spotify Audience Network',
  triton: 'Omny / Triton',
  dovetail: 'PRX Dovetail',
  simplecast: 'Simplecast',
  sai: 'Spotify SAI',
  sounder: 'Sounder',
  voxnest: 'Voxnest / Spreaker',
  xandr: 'xandr',
};

export const providerOptions = [
  { value: 'acast', label: 'Acast' },
  { value: 'adswizz', label: 'AdsWizz' },
  { value: 'art19', label: 'Art19' },
  { value: 'art19_marketplace', label: 'Art19 Marketplace' },
  { value: 'audacy', label: 'Audacy' },
  { value: 'knit', label: 'Knit' },
  { value: 'megaphone', label: 'Megaphone' },
  { value: 'mtm', label: 'Spotify Audience Network' },
  { value: 'triton', label: 'Omny / Triton' },
  { value: 'pandora', label: 'Pandora' },
  { value: 'dovetail', label: 'PRX Dovetail' },
  { value: 'redcircle', label: 'RedCircle' },
  { value: 'simplecast', label: 'Simplecast' },
  { value: 'sai', label: 'Spotify SAI' },
  { value: 'sounder', label: 'Sounder' },
  { value: 'voxnest', label: 'Voxnest / Spreaker' },
  { value: 'xandr', label: 'xandr' },
];

// All player keys that we support in preferred order for SmartLink appearance
export const allPlayerKeys = [
  'itunes',
  'spotify',
  'google_podcasts',
  'overcast',
  'castbox',
  'pocket_casts',
  'podcast_addict',
  'radio_public',
  'breaker',
  'rss',
  'pandora',
  'amazon_music',
  'i_heart_radio',
  'youtube',
  'radio_com',
  'uforia',
  'custom',
];

// Player info for SmartLinks deeplinking
export const playerInfo = {
  itunes: { value: 'itunes', label: 'Apple Podcasts', icon: ItunesConnect },
  spotify: { value: 'spotify', label: 'Spotify', icon: Spotify },
  google_podcasts: {
    value: 'google_podcasts',
    label: 'Google Podcasts',
    icon: GooglePodcasts,
  },
  overcast: { value: 'overcast', label: 'Overcast', icon: OverCast },
  castbox: { value: 'castbox', label: 'Castbox', icon: CastBox },
  pocket_casts: {
    value: 'pocket_casts',
    label: 'Pocket Casts',
    icon: PocketCasts,
  },
  podcast_addict: {
    value: 'podcast_addict',
    label: 'Podcast Addict',
    icon: PodcastAddict,
  },
  radio_public: {
    value: 'radio_public',
    label: 'Radio Public',
    icon: RadioPublic,
  },
  breaker: { value: 'breaker', label: 'Breaker', icon: Breaker },
  rss: { value: 'rss', label: 'RSS', icon: Rss },
  pandora: { value: 'pandora', label: 'Pandora', icon: Pandora },
  amazon_music: {
    value: 'amazon_music',
    label: 'Amazon Music',
    icon: AmazonMusic,
  },
  i_heart_radio: {
    value: 'i_heart_radio',
    label: 'iHeartRadio',
    icon: iHeartRadio,
  },
  youtube: { value: 'youtube', label: 'YouTube', icon: YouTube },
  radio_com: { value: 'radio_com', label: 'Audacy', icon: RadioDotCom },
  uforia: { value: 'uforia', label: 'Uforia', icon: Uforia },
  custom: {
    value: 'custom',
    label: 'Podcast Homepage',
    icon: CustomLinkDestination,
  },
};

export function localeFormattedTime(ts) {
  if (!ts) {
    return;
  }
  const locale = window.navigator.userLanguage || window.navigator.language;
  moment.locale(locale);
  const localeData = moment.localeData();
  const format = localeData.longDateFormat('LLL').replace(/.YYYY/, '');
  return moment(ts).format(format);
}

const colors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

export function mapPieChartColors(datasets) {
  const colorMap = {};
  let colorIndex = 0;
  for (let i = 0; i < datasets.length; i++) {
    if (!datasets[i]) {
      continue;
    }
    for (const [, dataset] of Object.entries(datasets[i])) {
      for (let j = 1; j < dataset.length; j++) {
        if (!colorMap[dataset[j][0]]) {
          colorMap[dataset[j][0]] = colors[colorIndex];
          colorIndex += 1;
        }
      }
    }
  }

  return colorMap;
}

// This function takes a date time and offsets it so that it is equal to it's equivalent utc time.
// i.e. 12:00 EDT -> 16 UTC so it would transform it to 16:00 EDT.
// This is because react-google-charts' has a formatter that allows me to offset the date, but it
// doesn't offset the hover date, only the dates on the X-axis
// -JS
export function addUtcOffset(date) {
  try {
    return moment(date).add(date.getTimezoneOffset(), 'minutes').toDate();
  } catch (e) {
    return null;
  }
}

// This function takes a date and floors it to its utc offset.
// i.e. 2019, 15 12:43:20 EDT -> 2019, 15 4:00:00 EDT
// I use this when working with 'react-datepicker' to turn it into a DAY picker. (Basically, I'm converting
// any dates selected into that day in UTC time)
// I do this because 'react-datepicker' doesn't allow offsetting timezones.
// (Actually it used to but they reverted the change).
// -JS
export function getFlooredUtcDateTime(date) {
  return moment(date).startOf('day').subtract(date.getTimezoneOffset(), 'minutes').toDate();
}

export function redirectToPricingPage(event, teamId) {
  window.location = `/teams/${teamId}/dashboard/plans`;
}

// https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(value) {
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const suffixNum = Math.floor(`${value}`.length / 3);
    let shortValue = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision),
      );
      const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

// same link as above, but this will turn 300,000 into "300K" instead of "0.3M", which is better for readability imo
export function abbreviateNumberTwo(num, fixed = 0) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return '0';
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  const c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed); // divide by power
  const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
  const e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

// react-select requires options to be in the format of { value: String, label: String }
// We end up having to remap values from the backend to this format regularly, so this
// function is a way to handle that. -JS
export function constructReactSelectOptionsFromStringArray(array, convertFromCamelCase = true) {
  return array.map(a => ({
    label: convertFromCamelCase ? camelCaseToSentenceCase(a) : a,
    value: a,
  }));
}

// source: https://stackoverflow.com/questions/4149276/how-to-convert-camelcase-to-camel-case
function camelCaseToSentenceCase(string) {
  return string.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

// source: https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function serialize(obj) {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
}

export class localStorageHelper {
  constructor(namespace = 'main') {
    this.namespace = namespace;
  }

  get(key) {
    const keyWithNamespace = `chartable:${this.namespace}:${key}`;
    if (!localStorage[keyWithNamespace]) return undefined;
    const c = JSON.parse(localStorage[keyWithNamespace]);
    return c;
  }

  set(key, val) {
    const keyWithNamespace = `chartable:${this.namespace}:${key}`;
    localStorage[keyWithNamespace] = JSON.stringify(val);
    return JSON.parse(localStorage[keyWithNamespace]);
  }
}

export function checkForCID(cid) {
  const re = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/,
  );
  return cid.match(re);
}

export function formatPlatformDataToGoogleCharts(data, metadata, headerTitle, headerKey, dataKey) {
  const cids = Object.keys(data);
  const header = [headerTitle];
  const zippedData = [];

  cids.forEach(cid => {
    Object.keys(data[cid]).forEach(source => {
      header.push(`${capitalizeFirstLetter(source)} - ${metadata[cid].title}`);
      data[cid][source].map((item, index) => {
        const row = zippedData[index] ? zippedData[index] : [];
        if (row.length === 0) {
          row.push(item[headerKey]);
        }

        row.push(item[dataKey]);
        zippedData[index] = row;
      });
    });
  });

  zippedData.unshift(header);
  return zippedData;
}

// Takes data formatted form our API
// {
//   "podcast1": [
//     {
//       "date": "01-02-2022",
//       "downloads": 3,
//     }
//   ]
//   "podcast2": [
//     {
//       "date": "01-02-2022",
//       "downloads": 3,
//     }
//   ]
// }
// and formats it for google charts
export function formatMultiplePodcastDataToGoogleCharts(data, metadata) {
  const keys = Object.keys(data);

  // I believe we should to fail hard here and allow a try/catch to handle fatal errors
  // instead of returning null if the data isn't there (see line below) -JS
  // if (keys.length === 0) return null;

  const firstPodcastKey = keys[0];

  const zippedData = [];
  data[firstPodcastKey].forEach((item, index) => {
    zippedData[index] = [data[firstPodcastKey][index].date];
    keys.forEach(key => {
      zippedData[index].push(data[key][index].downloads);
    });
  });

  zippedData.unshift(
    ['Date'].concat(
      keys.map(key => {
        return metadata[key].title;
      }),
    ),
  );

  return zippedData;
}

// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export function popupCenter(url, title, w, h) {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    width=${w}, 
    height=${h}, 
    top=${top}, 
    left=${left},
    popup
    `,
  );

  if (window.focus) newWindow.focus();
}
