import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Autosuggest from 'react-autosuggest';

import Loading from 'shared/Loading';

import './autosuggest.css';
import { debounce } from 'lodash';

import { toggleTrackPodcast, createByItunesUrl, createByFeedUrl } from 'shared/api';

const URL_ADD = 'url_add';
const SEARCH_ADD = 'search_add';
const FEED_ADD = 'feed_add';

const PodcastTracker = ({
  urlSearch,
  teamId,
  callback,
  method,
  imageClass,
  titleClass,
  placeholder,
  autofocus,
  wrapperClass,
}) => {
  const [autoSuggest, setAutoSuggest] = useState(!urlSearch);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [name, setName] = useState('');
  const [itunesUrl, setItunesUrl] = useState('');
  const [feedUrl, setFeedUrl] = useState('');
  const [error, setError] = useState(false);

  const handleAutosuggest = useCallback(
    debounce(({ value }) => {
      const params = { q: value.trim() };

      axios.get('/search', { params }).then(res => {
        setSuggestions(res.data.results);
      });
      return;
    }, 400),
    [],
  );

  const handleClearSuggestions = () => {
    setSuggestions([]);
    return;
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    setLoading(true);
    toggleTrackPodcast({ id: suggestion.slug, teamId }).then(
      res => {
        setLoading(false);
        callback({ success: true });
      },
      res => {
        setLoading(false);
        setError(res.response.data.error);
        callback(res.response.data);
      },
    );
    return;
  };

  const handleCreateByFeedUrl = () => {
    setLoading(true);
    createByFeedUrl({ url: feedUrl, teamId: teamId })
      .then(res => {
        setLoading(false);
        callback({ success: true });
      })
      .catch(res => {
        setLoading(false);
        setError(res.response.data.error);
        callback(res.response.data);
      });
  };

  const handleCreateByItunesUrl = () => {
    setLoading(true);
    createByItunesUrl({ url: itunesUrl, teamId: teamId })
      .then(res => {
        setLoading(false);
        callback({ success: true });
      })
      .catch(res => {
        setLoading(false);
        setError(res.response.data.error);
        callback(res.response.data);
      });
  };

  const onItunesUrlChange = e => {
    setItunesUrl(e.target.value);
    setError(null);
  };

  const onFeedUrlChange = e => {
    setFeedUrl(e.target.value);
    setError(null);
  };

  const renderSuggestion = suggestion => {
    const displayTitle =
      suggestion.title.length > 50 ? `${suggestion.title.slice(0, 50)}...` : suggestion.title;

    imageClass = imageClass || 'w-20 pr2';
    titleClass = titleClass || 'w-80';

    return (
      <div className="pointer link pa2 flex flex-wrap items-center">
        <div className={imageClass}>
          {suggestion.displayImageUrl && (
            <img className="tc center" title={suggestion.title} src={suggestion.displayImageUrl} />
          )}
        </div>
        <div className={titleClass}>
          <div className="f6 tl" title={suggestion.title}>
            {suggestion.tracked ? '✅' : ''}
            {displayTitle}
          </div>
        </div>
      </div>
    );
  };

  const renderItunes = () => {
    return (
      <div>
        <div className={`tc`}>
          <input
            type="text"
            placeholder="Apple Podcasts URL"
            name="url"
            onChange={onItunesUrlChange}
            className="pa1 db b--moon-gray ba br2 silver w-100"
          />
          <div
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            className="w-50 mt3 pa2 db bg-dark-blue pointer f5 dim white dib br2 ml1"
            onClick={handleCreateByItunesUrl}
          >
            Add podcast
          </div>
        </div>
        {error && <div className="red">{error}</div>}
      </div>
    );
  };

  const renderFeedUrl = () => {
    return (
      <div>
        <div className={`tc`}>
          <input
            type="text"
            placeholder="RSS Feed URL"
            name="url"
            onChange={onFeedUrlChange}
            className="pa1 db b--moon-gray ba br2 silver w-100"
          />
          <div
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            className="w-50 mt3 pa2 db bg-dark-blue pointer f5 dim white dib br2 ml1"
            onClick={handleCreateByFeedUrl}
          >
            Add podcast
          </div>
        </div>
        {error && <div className="red">{error}</div>}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={handleAutosuggest}
          onSuggestionsClearRequested={handleClearSuggestions}
          onSuggestionSelected={handleSuggestionSelected}
          getSuggestionValue={suggestion => suggestion.trackName}
          shouldRenderSuggestions={value => {
            return true;
          }}
          renderSuggestion={renderSuggestion}
          inputProps={{
            className: 'pa1 b--moon-gray ba br2 silver w-100 w5',
            placeholder: placeholder ? placeholder : 'Search...',
            value: name && name.length ? name : '',
            onChange: (e, { newValue }) => {
              if (newValue !== null) {
                setName(newValue);
              }
            },
            autoFocus: autofocus,
          }}
        />
        <div onClick={e => showUrlSearchBar()} className="f7 fl mt1 dark-blue header-font mb2 b">
          Don't see your podcast?
          <span className="link underline ml1 pointer">Search by iTunes url.</span>
        </div>
      </div>
    );
  };

  const renderInput = () => {
    switch (method) {
      case URL_ADD:
        return renderItunes();
      case FEED_ADD:
        return renderFeedUrl();
      case SEARCH_ADD:
        return renderSearch();
    }
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return <div className={wrapperClass}>{renderInput()}</div>;
};

export default PodcastTracker;
