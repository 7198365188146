import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getPodcast, getSellerTeams, postPromo, postPromoRequest } from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import Header from 'shared/Header';
import Loading from 'shared/Loading';
import TeamSelector from 'shared/TeamSelector';

import TopBar from '../TopBar';
import BasicInfoForm from './components/BasicInfoForm';
import DetailForm from './components/DetailForm';

const steps = {
  one: 1,
  two: 2,
};

const NOT_FOUND = 'NOT_FOUND';

function PromoForm({
  match,
  location,
  history,
  onCreatePromo,
  limitBanner,
  reachedPromoLimit,
  canIgnoreTrackableErrorOnSmartPromoCreation,
}) {
  const { teamId } = match.params;

  const urlParams = new URLSearchParams(location.search);

  const initialPodcastId = urlParams.get('podcastId');
  const initialCampaignType = urlParams.get('campaignType');
  const redirectedFromCreateAnother = urlParams.get('success');

  const [step, setStep] = useState(steps.one);

  const [loadingInitialPodcast, setLoadingInitialPodcast] = useState(true);

  const [promotedPodcast, setPromotedPodcast] = useState(null);
  const [promotingPodcast, setPromotingPodcast] = useState(null);
  const [campaignType, setCampaignType] = useState(() => {
    switch (initialCampaignType) {
      case 'baked_in':
        return { value: 'baked_in', label: 'Baked-in' };
      case 'dai':
        return { value: 'dai', label: 'Dynamic' };
      default:
        return null;
    }
  });

  const [airType, setAirType] = useState(null);
  const [airDate, setAirDate] = useState(null);
  const [episode, setEpisode] = useState(null);
  const [provider, setProvider] = useState(null);
  const [name, setName] = useState('');
  const [publisherName, setPublisherName] = useState('');

  const [manualIsInternal, setManualIsInternal] = useState(true);
  const [createAnother, setCreateAnother] = useState(false);

  const [campaignTypeExplainerIsOpen, setCampaignTypeExplainerIsOpen] = useState(false);
  const [approvalExplainerIsOpen, setApprovalExplainerIsOpen] = useState(false);

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [bannerNotice, setBannerNotice] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [sellerTeams, setSellerTeams] = useState([]);
  const [selectedSellerTeam, setSelectedSellerTeam] = useState(null);
  const getTeams = useCallback(async () => {
    try {
      const res = await getSellerTeams({ teamId });
      setSellerTeams(res.data);
    } catch (e) {
      return;
    }
  }, [teamId]);

  useEffect(() => {
    if (redirectedFromCreateAnother) {
      setBannerNotice(
        <BannerNotice
          onClick={() => setBannerNotice(null)}
          className="ph5 mt3 mw7"
          rounded
          success
          message="Successfully created promo"
        />,
      );
    }

    if (!initialPodcastId) {
      setLoadingInitialPodcast(false);
    } else {
      getPodcast({ podcastId: initialPodcastId, teamId })
        .then(res => setPromotedPodcast(res.data))
        .finally(() => setLoadingInitialPodcast(false));
    }
    getTeams();
  }, [getTeams, initialPodcastId, redirectedFromCreateAnother, teamId]);

  let backUrl;

  if (location.state && location.state.backUrl) {
    backUrl = location.state.backUrl;
  }

  const isInternal =
    promotingPodcast && promotingPodcast.id !== 'run-of-network-promotion'
      ? promotingPodcast.claimedByCurrentTeam
      : manualIsInternal;

  const enableCreateButton =
    promotedPodcast &&
    campaignType &&
    ((campaignType.value === 'baked_in' && promotedPodcast && (airDate || episode)) ||
      (campaignType.value === 'dai' && (!!provider || !isInternal)));

  function handleSetStep(newStep) {
    if (newStep === steps.one) {
      setAirType(null);
      setAirDate(null);
      setEpisode(null);
      setProvider(null);
      setName('');
      setPublisherName('');
    } else if (newStep === steps.two) {
      setName(
        `${promotedPodcast.title} x ${
          promotingPodcast && promotingPodcast.id !== 'run-of-network-promotion'
            ? promotingPodcast.title
            : 'RON'
        } ${campaignType.value === 'dai' ? 'Dynamic' : 'Baked-in'} Promo - ${moment().format(
          'MM/DD/YYYY',
        )}`,
      );
    }
    setStep(newStep);
  }

  function handleCreatePromo() {
    setShowErrors(false);
    const publisherIsNotSelected = !(selectedSellerTeam && selectedSellerTeam.value !== NOT_FOUND);
    if (name === '' || (!isInternal && publisherIsNotSelected && publisherName === '')) {
      setShowErrors(true);
      return;
    }

    if (requestInProgress) return;
    setRequestInProgress(true);

    const params = {
      teamId,
      campaignType: campaignType.value,
      buyerPodcastId: promotedPodcast.slug, // FIXME - should be using cid
      sellerPodcastId: promotingPodcast ? promotingPodcast.id : null,
      episodeId: episode ? episode.id : null,
      scheduledAirDate: airDate,
      pixelProvider: provider ? provider.value : null,
      promoName: name ? name : null,
      publisherName: publisherName ? publisherName : '',
      sellerTeamId:
        selectedSellerTeam && selectedSellerTeam.value !== NOT_FOUND
          ? selectedSellerTeam.value
          : null,
    };

    if (isInternal) {
      postPromo(params)
        .then(res => {
          onCreatePromo(res.data);
          if (createAnother) {
            window.location.assign(
              `/teams/${teamId}/dashboard/promos/new?podcastId=${promotedPodcast.slug}&campaignType=${campaignType.value}&success=true`,
            );
          } else {
            history.push(`/teams/${teamId}/dashboard/promos/${res.data.audioAdCampaignId}/manage`);
          }
        })
        .catch(err => {
          if (err.response.status === 409) {
            const existingPromo = err.response.data;
            const url = `analytics/${existingPromo.audioAdPlacementId}`;

            setBannerNotice(
              <Banner className="ph5 mt3 mw7">
                <div className="mb2 f6">
                  You already have a promo for this{' '}
                  {existingPromo.episode ? 'episode' : 'upcoming date'}
                </div>
                <a
                  style={{ color: 'inherit' }}
                  className="dim pointer hover underline f6"
                  href={`/teams/${teamId}/dashboard/promos/${existingPromo.audioAdCampaignId}/${url}`}
                >
                  <b>Click here to go to your existing promo →</b>
                </a>
              </Banner>,
            );
            window.scrollTo(0, 0);
          }
          setRequestInProgress(false);
        })
        .catch(() => {
          setBannerNotice(
            <BannerNotice
              onClick={() => setBannerNotice(null)}
              className="ph5 mt3 mw7"
              rounded
              error
              message="There was an error creating your promo. Reach out to support if this problem persists."
            />,
          );
          setRequestInProgress(false);
        });
    } else {
      postPromoRequest(params)
        .then(res => {
          onCreatePromo(res.data);
          if (createAnother) {
            window.location.assign(
              `/teams/${teamId}/dashboard/promos/new?podcastId=${promotedPodcast.id}&campaignType=${campaignType.value}&success=true`,
            );
          } else {
            history.push(
              `/teams/${teamId}/dashboard/promos/${res.data.audioAdCampaignId}/manage/requests`,
            );
          }
        })
        .catch(err => {
          if (err.response.status === 409) {
            const existingPromo = err.response.data;

            const url = existingPromo.audioAdPlacementId
              ? `analytics/${existingPromo.audioAdPlacementId}`
              : `manage/requests`;

            setBannerNotice(
              <Banner className="ph5 mt3 mw7">
                <div className="mb2 f6">
                  You already have a{' '}
                  {existingPromo.audioAdPlacementId ? 'promo' : 'promo request awaiting approval'}{' '}
                  for this {existingPromo.episode ? 'episode' : 'upcoming date'}
                </div>
                <a
                  style={{ color: 'inherit' }}
                  className="dim pointer hover underline f6"
                  href={`/teams/${teamId}/dashboard/promos/${existingPromo.audioAdCampaignId}/${url}`}
                >
                  <b>
                    Click here to{' '}
                    {existingPromo.audioAdPlacementId
                      ? 'go to your existing promo'
                      : 'see your promo requests awaiting approval'}{' '}
                    →
                  </b>
                </a>
              </Banner>,
            );
            window.scrollTo(0, 0);
          }
          setRequestInProgress(false);
        })
        .catch(() => {
          setBannerNotice(
            <BannerNotice
              onClick={() => setBannerNotice(null)}
              className="ph5 mt3 mw7"
              rounded
              error
              message="There was an error creating your promo. Reach out to support if this problem persists."
            />,
          );
          setRequestInProgress(false);
        });
    }
  }

  if (loadingInitialPodcast) {
    return (
      <div>
        <TopBar
          text={backUrl ? 'Back' : 'Analytics'}
          backUrl={backUrl ? backUrl : `/teams/${teamId}/dashboard/promos`}
        />
        <Loading fullScreen />
      </div>
    );
  }

  function renderTeamSelector() {
    if (!sellerTeams.length) return null;
    return (
      <TeamSelector
        data={sellerTeams}
        onSelect={selection => {
          setSelectedSellerTeam(selection);
        }}
        selected={selectedSellerTeam}
        label={"Select the publisher you're working with"}
        labelClassName="mb2 lh-copy dark-gray f4-5"
        selectorClassName="f5"
        customOption={{
          value: NOT_FOUND,
          label: <span className="i b">I can't find the publisher in this list</span>,
        }}
      />
    );
  }

  return (
    <div>
      <TopBar
        text={backUrl ? 'Back' : 'Analytics'}
        backUrl={backUrl ? backUrl : `/teams/${teamId}/dashboard/promos`}
      />
      {bannerNotice}
      {limitBanner && <div className="mw7 ph5 mt3">{limitBanner}</div>}
      <div className="ph5 mt4 mw7" style={{ marginBottom: '400px' }}>
        <div className="card pa3">
          <div className="mb4 items-center flex flex-wrap justify-between w-100">
            <div className="flex flex-row">
              <Header className="dark-blue" useNew>
                Create Promo
              </Header>
              <div className="ml2 flex flex-row items-center">
                <div
                  style={{ height: '20px', width: '20px', fontSize: '10px' }}
                  className="white bg-blue ba b--blue br-100 flex items-center justify-center f7"
                >
                  1
                </div>
                <div className="h0 w1 bb bt b--light-blue" />
                <div
                  style={{ height: '20px', width: '20px', fontSize: '10px' }}
                  className={`ba br-100 flex items-center justify-center f7 dark-gray ${
                    step === steps.two ? 'white bg-blue b--light-blue' : 'bg-white b--dark-gray'
                  }`}
                >
                  2
                </div>
              </div>
            </div>
            <a
              className="no-underline"
              target="_blank"
              href="https://chartable.helpscoutdocs.com/category/93-smartpromos"
            >
              <Button inverted type="primary">
                Get Help!
              </Button>
            </a>
          </div>
          {step === steps.one && (
            <React.Fragment>
              <BasicInfoForm
                canIgnoreTrackableErrorOnSmartPromoCreation={
                  canIgnoreTrackableErrorOnSmartPromoCreation
                }
                teamId={teamId}
                promotedPodcast={promotedPodcast}
                setPromotedPodcast={setPromotedPodcast}
                campaignType={campaignType}
                setCampaignType={setCampaignType}
                setCampaignTypeExplainerIsOpen={setCampaignTypeExplainerIsOpen}
                setApprovalExplainerIsOpen={setApprovalExplainerIsOpen}
                setName={setName}
                name={name}
                promotingPodcast={
                  (campaignType && campaignType.value === 'baked_in') || !!promotingPodcast
                    ? promotingPodcast
                    : {
                        id: 'run-of-network-promotion',
                        label: 'Run of Network or Multi-show promotion',
                        title: 'Run of Network or Multi-show promotion',
                      }
                }
                setPromotingPodcast={setPromotingPodcast}
                isInternal={isInternal}
                setManualIsInternal={setManualIsInternal}
              />
              <Button
                disabled={
                  !promotedPodcast ||
                  !campaignType ||
                  (campaignType &&
                    campaignType.value === 'baked_in' &&
                    // Special case APM to ignore trackable status gate
                    teamId !== 'apm' &&
                    (!promotingPodcast || (promotingPodcast && !promotingPodcast.trackableStatus)))
                }
                className="mt4"
                type="primary"
                onClick={() => handleSetStep(steps.two)}
              >
                Next →
              </Button>
            </React.Fragment>
          )}
          {step === steps.two && (
            <React.Fragment>
              <div className="flex flex-wrap pb3 mb3 bb b--light-gray" style={{ gap: '20px 0' }}>
                <div style={{ flex: '50%' }}>
                  <div className="f7 gray mb1 b">Promoting</div>
                  <div className="flex br1 flex-row items-center pr1 mw6 bg-white w-auto">
                    <img
                      className="mr1 w-auto"
                      style={{ height: '1.25rem' }}
                      src={promotedPodcast.imageUrl}
                      alt=""
                    />
                    <div className="f6 dark-gray truncate">{promotedPodcast.title}</div>
                  </div>
                </div>
                <div style={{ flex: '50%' }}>
                  <div className="f7 gray mb1 b">Promoted on</div>
                  <div className="flex br1 flex-row items-center pr1 mw6 bg-white w-auto">
                    {promotingPodcast && (
                      <img
                        className="mr1 w-auto"
                        style={{ height: '1.25rem' }}
                        src={promotingPodcast.imageUrl}
                        alt=""
                      />
                    )}
                    <div className="f6 dark-gray truncate">
                      {promotingPodcast
                        ? promotingPodcast.title
                        : 'Run of Network or multiple shows'}
                    </div>
                  </div>
                </div>
                <div style={{ flex: '50%' }}>
                  <div className="f7 gray mb1 b">Ad Type</div>
                  <div className="f6 dark-gray">
                    {campaignType.value === 'dai' ? 'Dynamic' : 'Baked-in'}
                  </div>
                </div>
              </div>
              <DetailForm
                manualIsInternal={manualIsInternal}
                setManualIsInternal={setManualIsInternal}
                teamId={teamId}
                showErrors={showErrors}
                campaignType={campaignType}
                promotingPodcast={
                  promotingPodcast && promotingPodcast.id !== 'run-of-network-promotion'
                    ? promotingPodcast
                    : null
                }
                isInternal={isInternal}
                provider={provider}
                setProvider={setProvider}
                setAirType={setAirType}
                airType={airType}
                airDate={airDate}
                setAirDate={setAirDate}
                episode={episode}
                setEpisode={setEpisode}
                publisherName={publisherName}
                setPublisherName={setPublisherName}
                name={name}
                setName={setName}
                setApprovalExplainerIsOpen={setApprovalExplainerIsOpen}
                selectedSellerTeam={selectedSellerTeam}
                renderTeamSelector={renderTeamSelector}
              />
              <Checkbox
                onClick={() => setCreateAnother(!createAnother)}
                small
                className="mt4 mid-gray"
                label="Save and create another promo"
                isChecked={createAnother}
              />
              {showErrors && (
                <div className="mt3 red f5">There are one or more errors with your promo</div>
              )}
              {reachedPromoLimit ? (
                <div className="mt3">{limitBanner}</div>
              ) : (
                <Button
                  loading={requestInProgress}
                  disabled={!enableCreateButton}
                  onClick={handleCreatePromo}
                  testId="createPromo"
                  className="mt3"
                  type="create"
                >
                  Create Promo
                </Button>
              )}
              <Button
                disabled={requestInProgress}
                onClick={() => handleSetStep(steps.one)}
                className="mt3"
              >
                Back
              </Button>
            </React.Fragment>
          )}
        </div>
        <BasicModal
          isOpen={campaignTypeExplainerIsOpen}
          onRequestClose={() => setCampaignTypeExplainerIsOpen(false)}
          ariaHideApp={false}
        >
          <div>
            <div className="f4 mb3 header-font mr2">What is the placement type for this promo?</div>
            <div className="f5 mid-gray lh-copy mb3">
              Placement type indicates how the ad will be delivered - Chartable measures dynamic ad
              insertion with a tracking pixel, and baked in ads with a prefix integration
            </div>
            <div className="f4 b dark-gray lh-copy">Dynamic</div>
            <div className="f5 mid-gray lh-copy mb3">
              Select this option if you are inserting the ad dynamically and will use a tracking
              pixel to deliver attribution data to Chartable
            </div>
            <div className="f4 b dark-gray lh-copy">Baked In</div>
            <div className="f5 mid-gray lh-copy mb4">
              Select this option if the ad will be read into the episode and you will use a prefix
              integration to deliver attribution data to Chartable
            </div>
            <Button
              type="primary"
              inverted
              className="w-100"
              onClick={() => setCampaignTypeExplainerIsOpen(false)}
            >
              Close
            </Button>
          </div>
        </BasicModal>
        <BasicModal
          isOpen={approvalExplainerIsOpen}
          onRequestClose={() => setApprovalExplainerIsOpen(false)}
          ariaHideApp={false}
        >
          <div>
            <div className="f4 mb2 dark-gray header-font mr2">Internal Promotion</div>
            <div className="mid-gray mb3 lh-copy f5">
              Internal means that the promotion is running on a podcast that is managed by your
              Chartable account.
            </div>
            <div className="f4 mb2 dark-gray header-font mr2">External Promotion</div>
            <div className="mid-gray mb2 lh-copy f5">
              External means that the promotion will be running on a podcast that is not managed by
              your Chartable account.
            </div>
            <div className="mid-gray mb3 lh-copy f5">
              External promotions will be assigned to the publisher's Chartable account for
              approval. If the publisher does not yet have a Chartable Publisher account, they'll
              need to create one in order to approve the SmartPromo.
            </div>
            <Button
              type="primary"
              inverted
              className="w-100"
              onClick={() => setApprovalExplainerIsOpen(false)}
            >
              Close
            </Button>
          </div>
        </BasicModal>
      </div>
    </div>
  );
}

PromoForm.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onCreatePromo: PropTypes.func.isRequired,
  limitBanner: PropTypes.element,
};

PromoForm.defaultProps = {
  limitBanner: null,
};

export default PromoForm;
