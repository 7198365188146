import moment from 'moment';

import { InputTypes } from './inputTypes';

const SMARTPROMOS_TIMESERIES_REPORT = [
  {
    header: 'Podcast',
    type: InputTypes.audioAdCampaignId,
    required: true,
  },
  {
    header: 'Date Range',
    type: InputTypes.dateRange,
    required: true,
    defaultValue: {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
    },
  },
  {
    header: 'Aggregation Window',
    type: InputTypes.aggregationWindow,
    defaultValue: 'day',
  },
];

const SMARTPROMOS_SNAPSHOT_REPORT = [];

const PODCAST_OVERVIEW_REPORT = [
  {
    header: 'Podcast (Leave blank to select all podcasts)',
    type: InputTypes.podcastId,
  },
];

const EPISODES_OVERVIEW_REPORT = [
  {
    header: 'Podcast (Leave blank to select all podcasts)',
    type: InputTypes.podcastId,
  },
];

const SMARTLINKS_OVERVIEW_REPORT = [
  {
    header: 'Podcast',
    type: InputTypes.podcastId,
  },
  {
    header: 'Episode',
    type: InputTypes.episodeId,
    dependsOn: 'podcastId',
  },
];

const SMARTLINKS_REPORT = [
  {
    header: 'SmartLink',
    type: InputTypes.trackingLinkId,
    required: true,
  },
];

const SMARTADS_OVERVIEW_REPORT = [
  {
    header: 'Ad Campaign(s)',
    key: 'adCampaignIds',
    type: InputTypes.adCampaignId,
    options: {
      allowsMultiSelect: true,
      allowsSelectAll: true,
    },
  },
  {
    header: 'Date Range',
    type: InputTypes.dateRange,
    required: true,
    defaultValue: {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
    },
  },
];

export {
  SMARTPROMOS_TIMESERIES_REPORT,
  SMARTPROMOS_SNAPSHOT_REPORT,
  PODCAST_OVERVIEW_REPORT,
  EPISODES_OVERVIEW_REPORT,
  SMARTLINKS_OVERVIEW_REPORT,
  SMARTLINKS_REPORT,
  SMARTADS_OVERVIEW_REPORT,
};
