import React from 'react';
import { Link } from 'react-router-dom';
import MeatballSVG from 'images/icons/meatball.svg';
import PropTypes from 'prop-types';

import ShareView from './ShareView';

const LinkInfo = ({
  canEditSmartLinks,
  trackingLink,
  teamId,
  setShowDropDown,
  showDropDown,
  setArchiveConfirmationModalIsOpen,
  isNew,
}) => {
  const AutomatedBadge = () => {
    return (
      <div
        style={{
          fontSize: '10px',
          position: 'relative',
          top: '-4px',
          padding: '2px',
          borderRadius: '2px',
        }}
        className="ba b--moon-gray di ml3"
      >
        AUTOMATED
      </div>
    );
  };

  return (
    <div className="card pa3 mb3 w-100 relative">
      <div className="flex flex-wrap items-center">
        <div className="w-100">
          <div className="flex flex-row pb2 b--light-gray bb">
            <div className="f4 dark-gray">
              {trackingLink.name || 'Untitled Link'}
              {trackingLink.isAutomated && <AutomatedBadge />}
            </div>
            <div className="flex flex-row gap ml-auto items-center self-start">
              {canEditSmartLinks && (
                <Link
                  className="no-underline br2 pa1 bg-blue white pointer dim w3 tc"
                  to={`/teams/${teamId}/dashboard/links/${trackingLink.id}/edit`}
                >
                  Edit
                </Link>
              )}
              <div className="relative">
                <img
                  alt="meatball"
                  src={MeatballSVG}
                  onClick={() => setShowDropDown(!showDropDown)}
                  className="pointer dim h1 w-auto"
                />
                {showDropDown && (
                  <div
                    className="bg-white br2 shadow-4 ba b--moon-gray absolute w4 right-0 mt2"
                    style={{ zIndex: 999 }}
                  >
                    <a
                      href={`/tracking_links/compare_links?team_id=${teamId}&tracking_link_id=${trackingLink.id}`}
                      className="no-underline"
                    >
                      <div className="dark-gray pointer hover-blue pa2 b--light-gray bb">
                        Compare
                      </div>
                    </a>
                    <a
                      target="_blank"
                      href={trackingLink.slugUrl}
                      className="no-underline"
                      rel="noreferrer"
                    >
                      <div className="dark-gray pointer hover-blue pa2 b--light-gray bb bt">
                        Preview
                      </div>
                    </a>
                    {canEditSmartLinks && (
                      <div
                        onClick={() => setArchiveConfirmationModalIsOpen(true)}
                        className="dark-gray pointer hover-blue pa2 b--light-gray bt"
                      >
                        Archive
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt2">
            <ShareView trackingLink={trackingLink} initialShowShareOptions={isNew} />
          </div>
        </div>
      </div>
    </div>
  );
};

LinkInfo.propTypes = {
  canEditSmartLinks: PropTypes.bool,
  trackingLink: PropTypes.object,
  teamId: PropTypes.string,
  setShowDropDown: PropTypes.func,
  showDropDown: PropTypes.any,
  setArchiveConfirmationModalIsOpen: PropTypes.func,
  isNew: PropTypes.bool,
};

export default LinkInfo;
