import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ComponentRegistry from './components/ComponentRegistry';
import PartnerDashboard from './components/PartnerDashboard';
import PromoRouter from './components/PromoRouter';
import SpotifyDashboard from './components/SpotifyDashboard';
import UserDashboard from './components/UserDashboard';

const AdminRouter = props => {
  return (
    <div className="flex flex-row">
      <div className="ph2 vh-100 br b--moon-gray flex flex-column" style={{ width: '16rem' }}>
        <div className="mv3 w4 flex items-center justify-center w-100">
          <img
            className="h-auto w-60 mb2"
            src="https://chartable.com/assets/logo-text-gray-26b195a25ce6db1e678f9b15b0a34ed038d8dd81c0e6c9d70c3a17145d1a797f.png"
          />
          <div className="ml2 ttu gray">admin</div>
        </div>
        <div className="ph2 f5">
          <div style={{ fontSize: '1.2rem' }} className="mb1 ph2 pb2 header-font blue">
            SmartPromos
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/promos')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/promos' ? 'bg-light-gray' : ''
            }`}
          >
            Overview
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/promos/approval_manager')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/promos/approval_manager'
                ? 'bg-light-gray'
                : ''
            }`}
          >
            Approval Manager
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/promos/episode_filler')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb4 ${
              props.location.pathname == '/custom_admin/react/promos/episode_filler'
                ? 'bg-light-gray'
                : ''
            }`}
          >
            Episode Filler
          </div>
          <div style={{ fontSize: '1.2rem' }} className="mb1 ph2 pb2 header-font blue">
            Product & Engineering
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/product/component_registry')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/product/component_registry'
                ? 'bg-light-gray'
                : ''
            }`}
          >
            Component Registry
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/partners')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/partners' ? 'bg-light-gray' : ''
            }`}
          >
            Manage Team Partners
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/spotify_dashboard')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/spotify_dashboard'
                ? 'bg-light-gray'
                : ''
            }`}
          >
            Spotify Dashboard
          </div>
          <div
            onClick={() => props.history.push('/custom_admin/react/users')}
            style={{ fontSize: '1.1rem' }}
            className={`br2 pa2 hover-bg-light-gray pointer b mb1 ${
              props.location.pathname == '/custom_admin/react/users'
                ? 'bg-light-gray'
                : ''
            }`}
          >
            Users
          </div>
        </div>
      </div>
      <div className="w-100">
        <Switch>
          <Route path="/custom_admin/react/promos" component={PromoRouter} />
          <Route path="/custom_admin/react/product" component={ComponentRegistry} />
          <Route path="/custom_admin/react/partners" component={PartnerDashboard} />
          <Route path="/custom_admin/react/spotify_dashboard" component={SpotifyDashboard} />
          <Route path="/custom_admin/react/users" component={UserDashboard} />
        </Switch>
      </div>
    </div>
  );
};

function CustomAdmin() {
  return (
    <Router>
      <Route path="/custom_admin/react" component={AdminRouter} />
    </Router>
  );
}

export default CustomAdmin;
